@charset "UTF-8";

@import "../mixin";

.top-main {
  padding: 50px 0 145px 0;
  overflow: hidden;
  display: block;

  @include sp {
    padding: 30px 0 120px 0;
    background: url("../../images/top/top-main-bg2-sp.png") no-repeat bottom center;
  }
  &--bg {
    position: absolute;
    top: -50px;
    left: calc(50% - 806px);
    z-index: 1;
    opacity: 0;

    @include sp {
      top: -40px;
      left: calc(50% - 195px);
      opacity: 1;

      animation: none;
    }
    animation: showBg .8s cubic-bezier(.86, 0, .07, 1) .5s forwards;
  }
  &--ttl {
    position: relative;
    z-index: 1;
    opacity: 0;
    animation: showTtl .8s cubic-bezier(.86, 0, .07, 1) .7s forwards;
    @include sp {
      margin: 0 0 15px 0;
    }
  }
  &--ttl_img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;

    @include sp {
      width: 220px;
    }
  }
  &--visual {
    width: 800px;
    height: 405px;
    position: relative;
    opacity: 0;
    border-radius: 100px;
    overflow: hidden;
    margin: 0 auto;
    animation: showVisual .8s cubic-bezier(.86, 0, .07, 1) .1s forwards;
    @include sp {
      width: calc(100% + 30px);
      height: auto;
      position: relative;
      left: -15px;
      border-radius: 0;
    }
  }
  &--visual_bg {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &--visual_img {
    display: block;
    margin: 0 auto;

    @include sp {
      width: 120%;
      position: relative;
      left: -10%;
    }
  }
  &--visual_anime {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &.show {
      display: none;
    }
  }
  @keyframes showBg {
    0% {
      top: -45px;
      opacity: 0;

      transform: scale(1.05, 1.05);
    }
    100% {
      top: -50px;
      opacity: 1;

      transform: scale(1, 1);
    }
  }
  @keyframes showVisual {
    0% {
      opacity: 0;

      transform: scale(.98, .98);
    }
    100% {
      opacity: 1;

      transform: scale(1, 1);
    }
  }
  @keyframes showTtl {
    0% {
      opacity: 0;

      transform: scale(1.05, 1.05);
    }
    100% {
      opacity: 1;

      transform: scale(1, 1);
    }
  }
}

.top-news {
  margin: -130px 0 0 0;
  padding: 0 0 50px 0;

  @include sp {
    margin-top: -70px;
    padding-bottom: 30px;
  }
  &--box {
    width: 640px;
    @include sp {
      width: 100%;
    }
  }
  &--content {
    display: flex;
    flex-wrap: wrap;
  }
  &--bnr {
    margin: -30px 0 0 20px;
    @include sp {
      margin: 30px 0 20px 0;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  &--ttl {
    display: block;
    margin: 0 0 30px 0;

    @include sp {
      margin-bottom: 15px;
    }
  }
  &--ttl_img {
    display: block;
    margin: 0 auto;
    @include sp {
      height: 100px;
      width: auto;
    }
  }
  &--more_btn {
    display: block;
    width: 140px;
    height: 38px;
    margin: 0 0 0 auto;
    position: relative;
    right: 13px;
    font-size: 14px;
    line-height: 38px;
    background-color: $colorBrown;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;

    transition: .15s;
    border: 2px solid $colorBrown;
    border-top: 0;
    &:after {
      content: $iconArrow;
      margin: 0 0 0 1px;
      position: relative;
      top: 3px;
      font-size: 20px;
      color: #fff;

      @include icon;
      transition: .15s;
    }
    &:hover {
      background-color: #fff;
      color: $colorBrown;
      &:after {
        color: $colorBrown;
      }
    }
  }
}

.top-lineup {
  padding: 30px 0;
  background: url("../../../images/top/top-banner-bg.png");
  box-sizing: border-box;
  @include sp {
    padding: 30px 0 30px 0;
  }
  &--inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &--link {
    display: block;

    @include sp {
      margin: 0 auto 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--link_img {
    max-width: 100%;
    height: auto;
  }
}
